var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pt-1" },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "pt-0 pl-0 pr-0", attrs: { cols: "12" } },
            [
              _c(
                "v-container",
                {
                  staticClass: "pt-0",
                  staticStyle: {
                    "max-height": "550px",
                    position: "relative",
                    "padding-bottom": "0",
                  },
                  attrs: { id: "tblCarriers", fluid: "" },
                },
                [
                  _c("v-data-table", {
                    staticClass: "elevation-2",
                    staticStyle: {
                      "max-height": "550px",
                      "overflow-y": "scroll",
                    },
                    attrs: {
                      id: "tblData",
                      headers: _vm.headers,
                      items: _vm.carriers,
                      "calculate-widths": "",
                      "hide-default-footer": "",
                      "fixed-header": "",
                      "items-per-page": _vm.pageSize,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item.name",
                        fn: function ({ item }) {
                          return [
                            _c(
                              "router-link",
                              {
                                staticClass: "name-link",
                                attrs: {
                                  to: {
                                    name: "Carrier Detail",
                                    params: { id: item.id },
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(item.name) + " ")]
                            ),
                          ]
                        },
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("v-page", {
                              staticClass: "pt-3 pr-3 pb-3",
                              attrs: {
                                "total-row": _vm.totalRecord,
                                "page-size-menu": [10, 20, 50, 100],
                              },
                              on: { "page-change": _vm.readDataFromAPI },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }