var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "pb-0 pt-0",
      staticStyle: { "max-width": "1600px" },
      attrs: { fluid: "" },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "div",
            { staticClass: "pa-2 header-banner" },
            [
              _c("v-row", [
                _c("div", { staticClass: "col-md-9 d-flex align-center" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "white rounded-circle d-inline-block align-center text-center short-name",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "transition-swing font-weight-bold grey--text",
                          staticStyle: { "font-size": "48px !important" },
                        },
                        [_vm._v(" " + _vm._s(_vm.shortFactoringName) + " ")]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "white--text ml-4" },
                    [
                      _c(
                        "v-list-item-title",
                        {
                          staticClass: "mb-1",
                          staticStyle: { "font-size": "24px" },
                        },
                        [_vm._v(" " + _vm._s(_vm.currentFactoring.name) + " ")]
                      ),
                      _c("div", { staticStyle: { "font-size": "18px" } }, [
                        _vm._v(" " + _vm._s(_vm.currentFactoring.city)),
                        _vm.currentFactoring.state
                          ? _c("span", [_vm._v(",")])
                          : _vm._e(),
                        _vm._v(" " + _vm._s(_vm.currentFactoring.state) + " "),
                      ]),
                    ],
                    1
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            [
              _c(
                "v-tabs",
                {
                  attrs: { centered: "" },
                  on: { change: _vm.changeTab },
                  model: {
                    value: _vm.currentTab,
                    callback: function ($$v) {
                      _vm.currentTab = $$v
                    },
                    expression: "currentTab",
                  },
                },
                [
                  _c("v-tab", { attrs: { href: "#tab-1" } }, [
                    _vm._v(" Details "),
                  ]),
                  _c("v-tab", { attrs: { href: "#tab-2" } }, [
                    _vm._v(" Carriers "),
                  ]),
                  _c("v-tab", { attrs: { href: "#tab-3" } }, [
                    _vm._v(" Payments "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-progress-linear", {
            attrs: {
              active: _vm.loadingIcon,
              color: "amber",
              indeterminate: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticStyle: { background: "none" },
          model: {
            value: _vm.currentTab,
            callback: function ($$v) {
              _vm.currentTab = $$v
            },
            expression: "currentTab",
          },
        },
        [
          _c(
            "v-tab-item",
            { attrs: { value: "tab-1" } },
            [_c("factoring-details", { attrs: { "factoring-id": _vm.id } })],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-2" } },
            [
              _c("factoring-carriers", {
                ref: "carrierTab",
                attrs: { "factoring-id": _vm.id },
              }),
            ],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { value: "tab-3" } },
            [
              _c("factoring-payments", {
                ref: "paymentTab",
                attrs: { "factoring-id": _vm.id },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }